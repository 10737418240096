import { Box, Card, CardBody, CardHeader, Heading, Text, Stack, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react"
import { Field, FieldProps } from "formik"
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BORDER_DEFAULT, COLOUR_PRIMARY_LIGHT, RADIUS_PRIMARY } from "../../../theme"
import { Control } from "../control"
import { validateNonEmpty, validateOptional } from "../../../data/validations"
import { Label } from "./Label"
import { RAInputProps } from "./RAInputProps"
import { AccountContext } from "../../Account"
import { Named, Person } from "../../../data/classes"
import { FormError } from "../error"
import { SearchBar } from "../../search"

const R = require('ramda');

type Item = Named | Person

export interface MultiSelectionInputProps extends Omit<RAInputProps, 'validation'> {
  validation?: (arg0: string) => string | undefined
  initItems: (token: string, setItems: (a: any[]) => void, start: number, limit: number) => void
  fetchItems: (token: string, setItems: (a: any[]) => void, start: number, limit: number) => void
  fetchItemsWithSearch: (token: string, search: string, setItems: (a: any[]) => void, start: number, limit: number) => void
}

export const MultiSelectionInput: React.FC<MultiSelectionInputProps> = ({
  initItems,
  fetchItems,
  fetchItemsWithSearch,
  ...props
}) => {

  const { token } = useContext(AccountContext)
  const [items, setItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);
  const [search, setSearch] = useState<string>();

  const start = 0
  const limit = 10

  if (!token) {
    throw new Error("user not Authenticated")
  }

  function isNamed(item: Item): item is Named {
    return (item as Named) !== undefined;
  }

  function isPerson(item: Item): item is Person {
    return (item as Person) !== undefined;
  }

  const itemName = (item: any): string => {
    if (isNamed(item)) {
      return item.name;
    } else if (isPerson(item)) {
      return item.preferredName;
    }
    throw new Error("Unsupported type");
  }

  const addSelection = (item: Item, field: string, setFieldValue: (field: string, value: any) => void) => {
    if (!selectedItems.includes(item)) {
      const updatedSelections = [...selectedItems, item];
      setSelectedItems(updatedSelections);
      const selectedIds = updatedSelections.map((i: Item) => i.id)
      setFieldValue(field, selectedIds);
    }
  };

  const removeSelection = (item: Item, field: string, setFieldValue: (field: string, value: any) => void) => {
    const updatedSelections = selectedItems.filter((i: Item) => i.id !== item.id);
    setSelectedItems(updatedSelections);
    const selectedIds = updatedSelections.map((i: Item) => i.id)
    setFieldValue(field, selectedIds);
  };

  const isSelected = (item: Item) => {
    return R.any((selected: Item) => selected.id === item.id)(selectedItems)
  }

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    }, [setSearch]);

  const initializeSelected = useCallback(() => {
    initItems(token, setSelectedItems, start, limit);
  }, [token, initItems, start, limit])

  const loadItems = useCallback(() => {
    const loadData = async () => {
      if (search) {
        // additional thought: the search var in this function makes more sense being after setItems.
        fetchItemsWithSearch(token, search, setItems, start, limit)
      } else {
        fetchItems(token, setItems, start, limit)
      }
    };
    loadData()
    initializeSelected()
  }, [
    token,
    setItems,
    search,
    start,
    limit,
    fetchItems,
    fetchItemsWithSearch,
    initializeSelected
  ])

  useEffect(() => {
    if (token) {
      loadItems()
    }
  }, [token, loadItems]);

  return (

    <Field
      name={props.name || 'multi-selection-input-field'}
      validate={props.validation || (props.optional ? validateOptional : validateNonEmpty)} >
      {({ field, form }: FieldProps) => (
        <Control
          form={form}
          name={props.name || 'text-input'}
        >
          <Box pb={5} >
            {props.title && <Label optional={props.optional || false} title={props.title} />}
            {selectedItems.map((item) => (
              <Tag
                mr={2}
                py={2}
                key={item.id}
                size="lg"
                borderRadius={RADIUS_PRIMARY}
                bg={COLOUR_PRIMARY_LIGHT}
                border={BORDER_DEFAULT}
              >
                <TagLabel>{itemName(item)}</TagLabel>
                <TagCloseButton
                  onClick={() => removeSelection(item, field.name, form.setFieldValue)}
                  test-id="remove-button"
                />
              </Tag>
            ))}
          </Box>

          <Box >
            <SearchBar
              setSearch={setSearch}
              searchOnChange={true} />
          </Box>

          <Box pt={5}>
            <Box
              height={300}
              overflowY="auto">
              <Stack>
                {items.map((item) => (
                  <Card
                    key={item.id}
                    test-id="menu-item"
                    border={BORDER_DEFAULT}
                    onClick={() => !isSelected(item) && addSelection(item, field.name, form.setFieldValue)}
                    opacity={isSelected(item) ? 0.5 : 1} // Visual feedback for disabled state
                    pointerEvents={isSelected(item) ? "none" : "auto"}
                  >
                    <CardHeader>
                      <Heading size='sm'>{itemName(item)}</Heading>
                    </CardHeader>
                  </Card>
                ))}
                {items.length === 0 && (
                  <Box
                    textAlign="center"
                    p={10}
                    color="gray.500"
                  >
                    No options available
                  </Box>
                )}
              </Stack>
            </Box>
          </Box>

          <Box>
            {props.name && <FormError form={form} name={props.name} />}
          </Box>

        </Control>
      )
      }
    </Field >
  )
}
