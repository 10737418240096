import { Box, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { BORDER_DEFAULT, COLOUR_PRIMARY_LIGHT, COLOUR_SECONDARY_DARK, RADIUS_PRIMARY } from '../../theme'

interface StatTileProps {
  title: string
  value: number
  format?: string
  units?: string
  // TODO: This should be of type ResponsiveValue<Color> but lsp cannnot find it
}

export const StatTile = (props: StatTileProps) => {
  return (
    <Box
      h="100%"
      borderRadius={RADIUS_PRIMARY}
      p={3}
      bg={COLOUR_PRIMARY_LIGHT}
      border={BORDER_DEFAULT}
    >
      <Text size="s">{props.title}</Text>
      <Box pt={2} />
      <HStack justifyContent="unset">
        <Text fontSize="4xl">{props.format === "percentage" ? `${Number(props.value * 100).toFixed(1)}` : props.value}</Text>
        {props.units && <Text pl={3} fontSize="4xl" textColor={COLOUR_SECONDARY_DARK}>{props.units}</Text>}
      </HStack>
    </Box>
  )
}
