import { deleteStaffMember, inviteStaffMember, updateStaffMember } from '../../../data/request';
import React, { useContext } from 'react'
import { AnalyticaForm } from '../../form';
import { validateEmail, validateName } from '../../../data/validations';
import { TextInput } from '../../form/input';
import { Org, StaffMember } from '../../../data/classes';
import { fullname } from '../../../data/functions';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../Account';
import { ModalMode } from '../../Modal';

export interface StaffMemberModalProps {
  org: Org
  staffMember?: StaffMember
  mode: ModalMode
}

export const StaffMemberModal: React.FC<StaffMemberModalProps> = ({ ...props }) => {

  const { isAdmin } = useContext(AccountContext)
  const userIsAdmin = isAdmin()

  const navigate = useNavigate()
  const deleteAndReturn = (token: string, staffMember: StaffMember, callback: () => void) => {
    deleteStaffMember(token, staffMember, callback)
    navigate(userIsAdmin ? `/orgs/${props.org.id}` : '/staff')
  }

  let title = "Modal Title Placeholder"
  let description = "Modal description Placeholder"
  let initialValues

  switch (props.mode) {
    case "add":
      title = "Invite a Staff Member"
      description = `Invite a Staff Member to join ${props.org.name}. The new user will receive an email with their login details.`
      initialValues = { orgId: props.org.id }
      break
    case "update":
      if (!props.staffMember) {
        throw new Error("Item not supplied");
      }
      title = "Edit Staff Member"
      description = "Update Staff Member Details."
      initialValues = props.staffMember
      break
    case "delete":
      if (!props.staffMember) {
        throw new Error("Item not supplied");
      }
      title = "Delete Staff Member"
      description = `Are you sure you want to delete Staff Member - ${fullname(props.staffMember)}?`
      initialValues = { id: props.staffMember.id }
      break
  }

  return (
    <AnalyticaForm
      name='staff-member-modal'
      title={title}
      description={description}
      initialValues={initialValues}
      mode={props.mode}
      onSubmit={
        props.mode === 'update' ? updateStaffMember
          : props.mode === 'delete' ? deleteAndReturn
            : inviteStaffMember}
    >
      {
        props.mode !== 'delete' &&
        <>
          <TextInput
            name="email"
            title="Email Address"
            testId='staff-member-email-input'
            validation={validateEmail}
          />
          <TextInput
            name="preferredName"
            title="Preferred Name"
            testId='staff-member-preferred-name-input'
            validation={validateName}
            optional
          />
          <TextInput
            name="lastName"
            title="Last Name"
            testId='staff-member-last-name-input'
            validation={validateName}
            optional
          />
        </>
      }
    </AnalyticaForm>
  )
}
