import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Admin } from "../../../data/classes";
import { fullname } from "../../../data/functions";
import { fetchAdminById } from "../../../data/request";
import { AccountContext } from "../../Account";
import { PageController } from "../../controller/page-controller";
import { Loading } from "../../loading";
import { AdminModal } from "../../modal/admin";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { RAProfile } from "../profile";

export interface AdminTableTabProps extends AnalyticaTableTabProps {
  admin: Admin
}

export const AdminProfile = () => {

  const [admin, setAdmin] = useState<Admin | null>()

  let { adminId } = useParams();

  const { token, setPageTitle } = useContext(AccountContext)

  const load = useCallback(() => {
    setPageTitle("Admins")
    if (token && adminId) {
      fetchAdminById(token, adminId, setAdmin)
    }
  }, [setPageTitle, token, adminId])

  useEffect(() => {
    load()
  }, [load]);

  if (!admin) {
    return <Loading />
  }

  return (
    <PageController>
      <RAProfile
        primary={fullname(admin) || ''}
        secondary={admin.email}
        edit={
          <AdminModal mode="update" admin={admin} />
        }
        delete={
          <AdminModal mode="delete" admin={admin} />
        }
      />

    </PageController>
  )
}
