import { fetchAdmins, fetchAdminsWithSearch } from '../../../data/request';
import React, { ReactElement, useContext, useEffect } from 'react'
import { AnalyticaTable } from '../../table';
import { Box } from '@chakra-ui/react';
import { ICON_ADMIN } from '../../../theme';
import { AddButton } from '../../button';
import { fullname } from '../../../data/functions';
import { RTableColumn, RTableColumns } from '../../table/column';
import { RTableActions } from '../../table/action';
import { AdminModal } from '../../modal/admin';
import { AccountContext } from '../../Account';
import { PageController } from '../../controller/page-controller';
import { ModalContext } from '../../Modal';
import { Admin } from '../../../data/classes';
import { RTableColumnProps } from '../../table/column/r-table-column';

export const Admins = () => {

  const { setModalContent } = useContext(ModalContext)

  const { setPageTitle } = useContext(AccountContext)

  const fullnameComponent = (column: ReactElement<RTableColumnProps>, a: Admin) => {
    return fullname(a)
  }

  useEffect(() => {
    setPageTitle("Admins")
  }, [setPageTitle]);

  return (
    <Box
      paddingX={5}
    >
      <PageController>
        <AnalyticaTable
          icon={ICON_ADMIN}
          fetchItems={fetchAdmins}
          fetchItemsWithSearch={fetchAdminsWithSearch}
          searchable
          searchOnChange
          fullscreen
        >
          <RTableActions name='RTableActions'>
            <AddButton
              title='New Admin'
              testId="new-admin-button"
              onClick={() => setModalContent(
                <AdminModal mode='add' />
              )}
            />
          </RTableActions>

          <RTableColumns name='RTableColumns'>
            <RTableColumn name="Name" component={fullnameComponent} />
            <RTableColumn id="email" name="Email Address" />
          </RTableColumns>

        </AnalyticaTable>

      </PageController>
    </Box>
  )
}
