import React, { PropsWithChildren } from 'react';

export interface RTableColumnsProps extends PropsWithChildren {
  name?: string
}

export const RTableColumns: React.FC<RTableColumnsProps> = ({ name = "RTableColumns", ...props }) => {
  return <>
    {props.children}
  </>
}
