import React from 'react';
import { App } from './App';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createHashRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { Login, PageNotFound, ProtectedRoute, ResetPassword, Signup, Verify } from './components';
import { Dashboard } from './components/dashboard';
import { Students } from './components/views/students';
import { Tests } from './components/views/tests';
import { Settings } from './components/settings';
import { Admins } from './components/views/admins';
import { Orgs } from './components/views/orgs';
import { OrgProfile } from './components/profile/org-profile';
import { TestProfile } from './components/profile/test-profile';
import { StaffMemberProfile } from './components/profile/staff-member-profile';
import { StudentProfile } from './components/profile/student-profile';
import { AdminProfile } from './components/profile/admin-profile';
import { Staff } from './components/views/staff';
import { Classes } from './components/views/classes';
import { UserProfile } from './components/profile/user-profile';
import { ClassProfile } from './components/profile/class-profile/class-profile';

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>

      <Route index element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route path="verify" element={<Verify />} />
      <Route path="reset-password" element={<ResetPassword />} />

      {/* all users */}
      <Route element={<ProtectedRoute roles={["admin", "staff"]} />}>
        <Route path="home" element={<Dashboard />} />
        {/* <Route path="orgs/:orgId/staff" element={<Staff />} /> */}
        {/* <Route path="orgs/:orgId/staff/:staffId/edit" element={<StaffForm />} /> */}
        {/* <Route path="orgs/:orgId/classes" element={<Classes />} /> */}
        {/* <Route path="orgs/:orgId/classes/:classId/edit" element={<ClassForm />} /> */}
        {/* <Route path="orgs/:orgId/classes/:classId/progress" element={<ClassProgress />} /> */}
        {/* <Route path="orgs/:orgId/classes/:classId/progress/feedback" element={<ClassFeedback />} /> */}
        <Route path="orgs/:orgId" element={<OrgProfile />} />
        <Route path="admins/:adminId" element={<AdminProfile />} />
        <Route path="orgs/:orgId/classes/:classId" element={<ClassProfile />} />
        <Route path="orgs/:orgId/tests/:testId" element={<TestProfile />} />
        <Route path="orgs/:orgId/staff/:staffMemberId" element={<StaffMemberProfile />} />
        <Route path="orgs/:orgId/students/:studentId" element={<StudentProfile />} />

        <Route path="orgs/:orgId/classes" element={<Classes />} />
        <Route path="orgs/:orgId/students" element={<Students />} />
        <Route path="orgs/:orgId/staff" element={<Staff />} />
        <Route path="orgs/:orgId/tests" element={<Tests />} />
        <Route path="admin" element={<Admins />} />

        {/* <Route path="orgs/:orgId/classes/:classId/students/:studentId/edit" element={<StudentForm />} /> */}
        {/* <Route path="orgs/:orgId/classes/:classId/students/import" element={<ImportStudents />} /> */}
        {/* <Route path="orgs/:orgId/classes/:classId/students/:studentId/progress" element={<StudentProgress />} /> */}
        <Route path="orgs/:orgId/classes/:classId/tests" element={<Tests />} />
        <Route path="profile" element={<UserProfile />} />
        <Route path="settings" element={<Settings />} />
      </Route>

      {/* admins only */}
      <Route element={<ProtectedRoute roles={["admin"]} />}>
        <Route path="admins" element={<Admins />} />
        <Route path="orgs" element={<Orgs />} />
      </Route>
      <Route path='*' element={<PageNotFound />} />
    </Route>
  )
);

let root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
