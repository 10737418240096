import React, { ReactNode, createContext, useEffect, useState, useCallback } from "react"
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute, CognitoUserSession } from "amazon-cognito-identity-js"
import { useIdleTimer } from "react-idle-timer";

declare const window: any;

interface CurrentUser {
  [key: string]: any
}

interface AccountContextType {
  token: string | undefined,
  user: CurrentUser | null,
  validSession: boolean,
  session: CognitoUserSession | null,
  verified: boolean,
  signUp: Function,
  verify: Function,
  resetPassword: Function,
  resendConfirmationCode: Function,
  authenticate: Function,
  getSession: Function,
  updateDetails: Function,
  logout: Function,
  getUserRoles: Function,
  isAdmin: Function,
  isStaff: Function,
  pageTitle: string,
  setPageTitle: Function,
  rerender: Function,
}

export const AccountContext = createContext<AccountContextType>({} as AccountContextType);
const GROUPS = "cognito:groups"

const poolData = {
  ClientId: process.env.NODE_ENV === 'production' ? window.env.COGNITO_CLIENT_ID : process.env.REACT_APP_COGNITO_CLIENT_ID,
  UserPoolId: process.env.NODE_ENV === 'production' ? window.env.COGNITO_USER_POOL_ID : process.env.REACT_APP_COGNITO_USER_POOL_ID
}

export interface AccountProps {
  children: ReactNode | ReactNode[]
}

export const Account: React.FC<AccountProps> = (props: AccountProps) => {

  const [validSession, setValidSession] = useState<boolean>(localStorage.getItem("validSession") === 'true' || false)
  const [session, setSession] = useState<CognitoUserSession | null>(null)
  const [token, setToken] = useState<string>()
  const [user, setUser] = useState<CurrentUser | null>(null)
  const [verified, setVerified] = useState<boolean>(true)

  const [userPool] = useState<CognitoUserPool>(new CognitoUserPool(poolData))
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)

  const [pageTitle, setPageTitle] = useState<string>("Dashboard")

  const [render, setRender] = useState<boolean>(false)

  const signUp = async (
    Email: string,
    Password: string,
    GivenName: string,
    FamilyName: string,
    onSuccess: Function,
    onError: Function
  ) => {
    userPool.signUp(Email, Password, [
      new CognitoUserAttribute({ Name: "given_name", Value: GivenName }),
      new CognitoUserAttribute({ Name: "family_name", Value: FamilyName }),
    ], [], (err, data) => {
      if (err) {
        onError(err)
      }
      onSuccess(data)
    })
  }

  const authenticate = async (
    Email: string,
    Password: string,
    onSuccess: Function,
    onError: Function,
    onNewPasswordRequired: Function
  ) => {
    const u = new CognitoUser({ Username: Email, Pool: userPool })
    const authDetails = new AuthenticationDetails({ Username: Email, Password })
    u.authenticateUser(authDetails, {
      onSuccess: (data) => {
        getSession()
        onSuccess(data)
      },
      onFailure: (err) => {
        onError(err)
      },
      newPasswordRequired: () => {
        setCognitoUser(u)
        getSession()
        onNewPasswordRequired()
      },
    })
  }

  const verify = async (
    Code: string,
    onSuccess: Function,
    onError: Function
  ) => {
    if (!cognitoUser) {
      return
    }
    cognitoUser.confirmRegistration(Code, true, (err: Error, data) => {
      if (err) {
        onError(err)
        return
      }
      onSuccess(data)
    })
  }

  const resetPassword = async (
    NewPassword: string,
    onSuccess: Function,
    onError: Function
  ) => {
    if (!cognitoUser) {
      return
    }
    cognitoUser.completeNewPasswordChallenge(NewPassword, {}, {
      onSuccess: (data) => {
        onSuccess(data)
      },
      onFailure: (err) => {
        onError(err)
      },
    })
  }

  const resendConfirmationCode = async (
    onSuccess: Function,
    onError: Function
  ) => {
    if (!cognitoUser) {
      return
    }
    cognitoUser.resendConfirmationCode((err: Error | undefined, data: any) => {
      if (err) {
        onError(err)
        return
      }
      onSuccess(data)
    })
  }

  const getSession = useCallback(
    async () => {
      let u = userPool.getCurrentUser()
      if (!u) {
        console.error("No Valid User!")
        return
      }
      u.getSession((err: Error, sess: CognitoUserSession | null) => {
        if (err) {
          console.error(err)
          return
        }
        setSession(sess ? sess : null)
        setCognitoUser(u)
        setValidSession(true)
      })
    }, [userPool])


  const updateDetails = async (
    GivenName: string,
    FamilyName: string,
    onSuccess: Function,
    onError: Function
  ) => {
    if (!cognitoUser) {
      return
    }
    cognitoUser.updateAttributes([
      new CognitoUserAttribute({ Name: "given_name", Value: GivenName }),
      new CognitoUserAttribute({ Name: "family_name", Value: FamilyName }),
    ], (err, data) => {
      if (err) {
        console.error(err)
        onError(err)
        return
      }
      onSuccess(data)
    })
  }

  const logout = () => {
    if (!cognitoUser) {
      return
    }
    cognitoUser.signOut()
    setSession(null)
    setValidSession(false)
    setCognitoUser(null)
    localStorage.clear()
  }

  const getUserRoles = () => {
    return (user && user[GROUPS]) ? user[GROUPS] : []
  }

  const isAdmin = () => {
    return user && user[GROUPS] && user[GROUPS].includes("admin")
  }

  const isStaff = () => {
    return user && user[GROUPS] && user[GROUPS].includes("staff")
  }

  const rerender = () => {
    setRender(!render)
  }

  const refreshSession = () => {
    const user = userPool.getCurrentUser(); // Get the current Cognito user
    if (!user) {
      console.warn("No current user found. Redirecting to login.");
      window.location.href = "/login"; // Redirect to login if no user is found
      return
    }
    user.getSession((err: any, session: CognitoUserSession) => {
      if (err) {
        console.error("Error getting session:", err);
        window.location.href = "/login"; // Redirect to login if session retrieval fails
        return
      }
      if (session.isValid()) {
        // console.log("Session is valid. No refresh needed.");
        return
      }
      // grab refresh token
      const refreshToken = session.getRefreshToken();
      if (!refreshToken) {
        console.error("No refresh token available. Redirecting to login.");
        window.location.href = "/login";
        return
      }
      // refresh the session
      user.refreshSession(refreshToken, (err, newSession) => {
        if (err) {
          console.error("Failed to refresh session:", err);
          window.location.href = "/login"; // Redirect to login if token refresh fails
          return
        }
        console.log("Session refreshed successfully. New tokens:", newSession);
        // Update any necessary state or UI with the new session details
      });
    });
  };

  const handleOnIdle = () => {
    console.log("User is idle. Checking token...");
    refreshSession();
  };

  // Set idle timer (e.g., 10 minutes of inactivity)
  useIdleTimer({
    timeout: 10 * 60 * 1000, // 10 minutes
    onIdle: handleOnIdle,
    debounce: 500, // Debounce time for idle detection
  });

  useEffect(() => {
    localStorage.setItem("validSession", validSession.toString())
    if (!session) {
      getSession()
      return
    }
    if (session.getAccessToken()) {
      setToken(session.getAccessToken().getJwtToken())
    }
    if (session.getIdToken()) {
      setUser(session.getIdToken().payload)
      setVerified(session.getIdToken().payload.email_verified)
    }
  }, [session, getSession, validSession, token, user, cognitoUser])

  return (
    <AccountContext.Provider value={{
      token,
      user,
      validSession,
      session,
      verified,
      signUp,
      verify,
      resetPassword,
      resendConfirmationCode,
      authenticate,
      getSession,
      updateDetails,
      logout,
      getUserRoles,
      isAdmin,
      isStaff,
      pageTitle,
      setPageTitle,
      rerender,
    }}>
      {props.children}
    </AccountContext.Provider>
  )
}
