import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Class, Org, StaffMember, Student } from '../../../data/classes';
import { fullname } from '../../../data/functions';
import { addStudent, deleteStudent, deleteStudentClasses, fetchClassesByOrgId, fetchClassesByOrgIdWithSearch, fetchClassesByStaffMemberId, fetchClassesByStaffMemberIdWithSearch, fetchClassesByStudentId, patchStudentClasses, updateStudent } from '../../../data/request';
import { validateMultiSelection, validateName, validateNonEmpty } from '../../../data/validations';
import { AnalyticaForm } from '../../form';
import { IntegerInput, MultiSelectionInput, TextInput } from '../../form/input';
import { ModalMode } from '../../Modal';

const R = require('ramda')

export type StudentModalMode = ModalMode | 'classes';

export interface StudentModalProps {
  org: Org
  clazz?: Class
  student?: Student
  staffMember?: StaffMember
  mode: StudentModalMode
}

export const StudentModal: React.FC<StudentModalProps> = ({ ...props }) => {

  const navigate = useNavigate()
  const deleteAndReturn = (token: string, student: Student, callback: () => void) => {
    deleteStudent(token, student, callback)
    navigate(`/orgs/${props.org.id}`)
  }

  const fetchClassesForStudent = (token: string, setItems: (a: any[]) => void, start: number, limit: number) => {
    if (props.student === undefined) {
      throw new Error("Student is undefined, cannot fetch classes")
    }
    fetchClassesByStudentId(token, props.student.id, setItems, start, limit);
  }

  const fetchDropdownClasses = (token: string, setItems: (a: any[]) => void, start: number, limit: number) => {
    if (props.staffMember) {
      fetchClassesByStaffMemberId(token, props.staffMember.id, setItems, start, limit);
    } else {
      fetchClassesByOrgId(token, props.org.id, setItems, start, limit);
    }
  }

  const fetchDropdownClassesWithSearch = (token: string, search: string, setItems: (a: any[]) => void, start: number, limit: number) => {
    if (props.staffMember) {
      fetchClassesByStaffMemberIdWithSearch(token, props.staffMember.id, search, setItems, start, limit);
    } else {
      fetchClassesByOrgIdWithSearch(token, props.org.id, search, setItems, start, limit);
    }
  }

  const updateStudentClasses = (token: string, s: Student, callback: Function) => {
    if (!props.student) {
      throw new Error("Student prop not supplied")
    }
    if (!props.student.classes) {
      throw new Error("Student Classes undefined")
    }
    // find diff
    const added = R.filter((id: string) => R.none((cid: string) => cid === id, props.student?.classes), s.classes)
    const removed = R.filter((id: string) => R.none((cid: string) => cid === id, s.classes), props.student?.classes)
    // patch added classes
    if (added && added.length > 0) {
      patchStudentClasses(token, props.student.id, added)
    }
    // delete removed classes
    if (removed && removed.length > 0) {
      deleteStudentClasses(token, props.student.id, removed)
    }
    callback()
  }

  let title
  let description
  let initialValues

  switch (props.mode) {
    case "add":
      title = "Add a new Student to "
      description = props.clazz ? `Add a new Student to ${props.clazz.name} at ${props.org.name}.`
        : `Add a new Student at ${props.org.name}.`
      initialValues = {
        level: 1,
        orgId: props.org.id,
        staff: props.staffMember && [props.staffMember.id],
        classes: props.clazz && [props.clazz.id]
      }
      break
    case "update":
      if (!props.student) {
        throw new Error("Item not supplied");
      }
      title = "Edit Student"
      description = "Update Student Details."
      initialValues = props.student
      break
    case "delete":
      if (!props.student) {
        throw new Error("Item not supplied");
      }
      title = "Delete Student"
      description = `Are you sure you want to delete Student - ${fullname(props.student)}?`
      initialValues = { id: props.student.id }
      break
    case "classes":
      if (!props.student) {
        throw new Error("Item not supplied");
      }
      title = "Edit Student Classes"
      description = `Update the assigned Classes for Student - ${fullname(props.student)}?`
      initialValues = { classes: props.student.classes }
      break
  }

  return (
    <AnalyticaForm
      name='student-modal'
      title={title}
      description={description}
      initialValues={initialValues}
      mode={props.mode === 'classes' ? 'update' : props.mode}
      onSubmit={
        props.mode === 'add' ? addStudent
          : props.mode === 'update' ? updateStudent
            : props.mode === 'delete' ? deleteAndReturn
              : updateStudentClasses}
    >
      {
        R.includes(props.mode, ['add', 'update']) &&
        <>
          <TextInput
            name="preferredName"
            title="Preferred Name"
            testId='student-preferred-name-input'
            validation={validateName}
          />
          <TextInput
            name="lastName"
            title="Last Name"
            testId='student-last-name-input'
            validation={validateName}
          />
          <TextInput
            name="identifier"
            title="Identifier"
            testId='student-identifier-input'
            validation={validateName}
          />
          <IntegerInput
            name="yearLevel"
            title="Year Level"
            testId='student-year-level-input'
            min={1}
            max={12}
            validation={validateNonEmpty}
          />
        </>
      }
      {
        props.mode === 'classes' &&
        <MultiSelectionInput
          name="classes"
          title="Classes"
          testId='student-class-input'
          validation={validateMultiSelection}
          initItems={fetchClassesForStudent}
          fetchItems={fetchDropdownClasses}
          fetchItemsWithSearch={fetchDropdownClassesWithSearch}
        />
      }
    </AnalyticaForm>
  )
}
