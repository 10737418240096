import { Input } from "@chakra-ui/react"
import { Field, FieldProps } from "formik"
import React from 'react'
import { BORDER_DEFAULT, COLOUR_PRIMARY_WHITE, RADIUS_PRIMARY } from "../../../theme"
import { Control } from "../control"
import { validateNonEmpty, validateOptional } from "../../../data/validations"
import { Label } from "./Label"
import { RAInputProps } from "./RAInputProps"
import { FormError } from "../error"

export interface TextInputProps extends Omit<RAInputProps, 'validation'> {
  validation?: (arg0: string) => string | undefined
}

export const TextInput = (props: TextInputProps) => {
  return (

    <Field
      name={props.name || 'text-input-field'}
      validate={props.validation || (props.optional ? validateOptional : validateNonEmpty)}>
      {({ field, form }: FieldProps) => (
        <Control
          form={form}
          name={props.name || 'text-input'}
        >
          {props.title && <Label optional={props.optional || false} title={props.title} />}
          <Input
            {...field}
            // placeholder={props.title}
            type={props.type || 'text'}
            test-id={props.testId}
            background={COLOUR_PRIMARY_WHITE}
            border={BORDER_DEFAULT}
            borderRadius={RADIUS_PRIMARY}
            w={props.w || "full"}
          />

          {props.name && <FormError form={form} name={props.name} />}

        </Control>
      )}
    </Field>
  )
}
