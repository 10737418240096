import { FormErrorMessage } from "@chakra-ui/react"
import React from "react"

interface Props {
  form: any
  name: string
}

export const FormError: React.FC<Props> = ({ form, name }) => (
  <FormErrorMessage>{(form.errors && form.errors[name]) ? form.errors[name]?.toString() : ""}</FormErrorMessage>
)
