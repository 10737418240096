import React from 'react'
import { AnalyticaForm } from '../../form';
import { IntegerInput, TextInput } from '../../form/input';
import { Class, Org, StaffMember } from '../../../data/classes';
import { addClass, deleteClass, updateClass } from '../../../data/request';
import { validateName, validateNonEmpty } from '../../../data/validations';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ModalMode } from '../../Modal';

export interface ClassModalProps {
  org: Org
  clazz?: Class
  staffMember?: StaffMember
  mode: ModalMode
}

export const ClassModal: React.FC<ClassModalProps> = ({ ...props }) => {

  const navigate = useNavigate()
  const deleteAndReturn = (token: string, clazz: Class, callback: () => void) => {
    deleteClass(token, clazz, callback)
    navigate(`/orgs/${props.org.id}`)
  }

  let title
  let description
  let initialValues

  switch (props.mode) {
    case "add":
      title = "Add a new Class"
      description = `Add a new Class to ${props.org.name}`
      initialValues = {
        orgId: props.org.id,
        staff: props.staffMember && [props.staffMember.id]
      }
      break
    case "update":
      if (!props.clazz) {
        throw new Error("Item not supplied");
      }
      title = "Edit Class"
      description = "Update Class Details."
      initialValues = props.clazz
      break
    case "delete":
      if (!props.clazz) {
        throw new Error("Item not supplied");
      }
      title = "Delete Class"
      description = `Are you sure you want to delete Class - ${props.clazz.name}?`
      initialValues = { id: props.clazz.id }
      break
  }

  return (
    <AnalyticaForm
      name='class-modal'
      title={title}
      description={description}
      initialValues={initialValues}
      mode={props.mode}
      onSubmit={
        props.mode === 'update' ? updateClass
          : props.mode === 'delete' ? deleteAndReturn
            : addClass}
    >
      {
        props.mode !== 'delete' &&
        <>
          <TextInput
            name="name"
            title="Name"
            testId='class-name-input'
            validation={validateName}
          />
          <Box pt={4} />
          <IntegerInput
            name="yearLevel"
            title="Year Level"
            testId='class-year-level-input'
            validation={validateNonEmpty}
            min={1}
            max={12}
          />
          {/* <MultiSelectionInput */}
          {/*   name="staff" */}
          {/*   title="Staff" */}
          {/*   testId='class-staff-input' */}
          {/*   validation={validateMultiSelection} */}
          {/*   fetchItems={fetchDropdownStaffWithSearch} */}
          {/* /> */}
        </>
      }
    </AnalyticaForm>
  )
}
