import React, { PropsWithChildren } from 'react';

export interface RTableActionsProps extends PropsWithChildren {
  name?: string
}

export const RTableActions: React.FC<RTableActionsProps> = ({ name = "RTableActions", ...props }) => {
  return <>
    {props.children}
  </>
}
