import { Box, Text, HStack } from '@chakra-ui/react';
import React from 'react';
import { BORDER_DEFAULT, FONT_PRIMARY, MARGIN_PRIMARY, RADIUS_PRIMARY } from '../../../theme';
import { StatTile } from '../../stats';
import { Data } from '../dashboard';

export interface StatsProps {
  data: Data
}

export const UpperStats: React.FC<StatsProps> = ({ ...props }) => {

  return (
    <Box
      p={MARGIN_PRIMARY}
      border={BORDER_DEFAULT}
      borderRadius={RADIUS_PRIMARY}
      m={MARGIN_PRIMARY}
      bg="white"
    >
      <Text fontSize={FONT_PRIMARY}>Statistics</Text>
      <HStack pt={5}>
        <StatTile title="Total Active Classes" value={props.data.totalClasses} />
        <StatTile title="Total Active Students" value={props.data.totalStudents} />
        <StatTile title="Tests Last Month" value={props.data.testsLastMonth} />
        <StatTile title="Average Score Percentage" units="%" value={props.data.avgScore} format="percentage" />
      </HStack>
    </Box>
  )
}

export default UpperStats;

