import React, { useState } from "react";
import { Student } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { fetchStudentsByStaffMemberId, fetchStudentsByStaffMemberIdWithSearch } from "../../../../data/request";
import { ICON_STAFF } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { StaffMemberTableTabProps } from "../staff-member-profile";

export const StaffMemberStudents: React.FC<StaffMemberTableTabProps> = ({ ...props }) => {

  const [render] = useState<boolean>(false)

  const fetchStudentsWithSearch = (token: string, search: string, setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByStaffMemberIdWithSearch(token, props.staffMember.id, search, setItems, start, limit);
  }

  const fetchStudents = (token: string, setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByStaffMemberId(token, props.staffMember.id, setItems, start, limit);
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStudents}
        fetchItemsWithSearch={fetchStudentsWithSearch}
        render={render}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/students"}
      >
        <RTableActions name='RTableActions'> </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Name" component={fullname} />
          <RTableColumn id="Level" name="Level" />
          <RTableColumn id="yearLevel" name="Year Level" />
          <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
          <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
        </RTableColumns>
      </AnalyticaTable>
    </>
  )
}
