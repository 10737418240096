import React, { useState } from 'react'
import { ModalContextProps } from '../Modal'
import { AnalyticaTableProps } from '../table/analytica-table'

export interface PageControllerProps extends React.PropsWithChildren {
  render?: boolean
}

export const PageController: React.FC<PageControllerProps> = (
  {
    render = false,
    ...props
  }
) => {

  const [rerender, setRender] = useState<boolean>(render)

  const closeAndRerender = () => {
    console.log("Closing and rerendering")
    setRender(!rerender)
  }

  const isTableElement = (element: React.ReactNode): element is React.ReactElement<AnalyticaTableProps> => {
    return React.isValidElement(element) && element.props
  }

  const isModalElement = (element: React.ReactNode): element is React.ReactElement<ModalContextProps> => {
    return React.isValidElement(element) && element.props
  }

  const content = React.Children.map(props.children, (child) => {
    if (!React.isValidElement(child)) {
      throw new Error("Invalid child passed to PageController!")
    } else if (isTableElement(child)) {
      return React.cloneElement(child, { render: render });
    } else if (isModalElement(child)) {
      return React.cloneElement(child, { onClose: closeAndRerender });
    }
    return child
  })

  return (
    <div>
      {content}
    </div>
  )
}
