import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Class, Org, Test } from '../../../data/classes';
import { deleteTest } from '../../../data/request';
import { AccountContext } from '../../Account';
import { ModalMode } from '../../Modal';

export interface TestModalProps {
  org: Org
  clazz: Class
  test?: Test
  mode: ModalMode
}

export const TestModal: React.FC<TestModalProps> = ({ ...props }) => {

  const { isAdmin } = useContext(AccountContext)
  const userIsAdmin = isAdmin()

  const navigate = useNavigate()
  const deleteAndReturn = (token: string, test: Test, callback: () => void) => {
    deleteTest(token, test, callback)
    navigate(userIsAdmin ? `/orgs/${props.org.id}/classes/${props.clazz.id}` : '/tests')
  }

  return (
    <></>
  )
}
