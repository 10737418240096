import { Tag } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { fetchTestsByStudentId, fetchTestsByStudentIdWithSearch, } from "../../../../data/request";
import { BORDER_DEFAULT, ICON_STAFF } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { StudentTableTabProps } from "../student-profile";
import { Test } from "../../../../data/classes";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { RTableActions } from "../../../table/action";
import { testTitle } from "../../../../data/functions";
import { RTableColumnProps } from "../../../table/column/r-table-column";

export const StudentTests: React.FC<StudentTableTabProps> = ({ ...props }) => {

  const fetchTestsWithSearch = (token: string, search: string, setItems: (t: Test[]) => void, start: number, limit: number) => {
    fetchTestsByStudentIdWithSearch(token, props.student.id, search, setItems, start, limit);
  }

  const fetchTests = (token: string, setItems: (t: Test[]) => void, start: number, limit: number) => {
    fetchTestsByStudentId(token, props.student.id, setItems, start, limit);
  }

  const testTitleComponent = (column: ReactElement<RTableColumnProps>, t: Test) => {
    return testTitle(t)
  }

  const testStatus = (t: Test) =>
    <Tag
      border={BORDER_DEFAULT}
      bg={t.completed ? "#F0FFF4" : "#FFF5EE"}
    >{t.completed ? "COMPLETED" : "PENDING"}</Tag>

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchTests}
        fetchItemsWithSearch={fetchTestsWithSearch}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/tests"}
      >
        <RTableActions name='RTableActions'></RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Created" component={testTitleComponent} />
          <RTableColumn name="Status" component={testStatus} />
          <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
          <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
        </RTableColumns>
      </AnalyticaTable>
    </>
  )
}
