import { Box, HStack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { ReactElement } from 'react';
import { BORDER_DEFAULT, BORDER_PRIMARY_DARK, BORDER_PRIMARY_LIGHT, COLOUR_PRIMARY_DARK, COLOUR_PRIMARY_DARK_OPACITY_6, COLOUR_PRIMARY_WHITE, COLOUR_TRANSPARENT, RADIUS_PRIMARY } from "../../theme";
import { AnalyticaTableTabProps } from "./analytica-table";

interface Props {
  children: ReactElement<AnalyticaTableTabProps> | ReactElement<AnalyticaTableTabProps>[]
}

export const AnalyticaTableContainer: React.FC<Props> = ({ ...props }) => {

  const last = React.Children.count(props.children) - 1;

  return (
    <Box
      paddingX={5}
      borderRadius={RADIUS_PRIMARY}
    >
      <Tabs
        variant="enclosed"
        isLazy
      >
        <TabList
        >
          {React.Children.map(props.children, (child: ReactElement<AnalyticaTableTabProps>, index: number) =>
            <Tab
              color={COLOUR_PRIMARY_DARK}
              bg={COLOUR_PRIMARY_WHITE}
              border={BORDER_DEFAULT}
              borderTopLeftRadius={index === 0 ? RADIUS_PRIMARY : 0}
              borderTopRightRadius={index === last ? RADIUS_PRIMARY : 0}
              _selected={
                {
                  color: COLOUR_PRIMARY_WHITE,
                  bg: COLOUR_PRIMARY_DARK,
                  border: BORDER_PRIMARY_DARK,
                }
              }
              test-id={child.props.testId}
            >
              <HStack
                py={2}
                px={5}
                spacing={3}
              >
                <Box>
                  {child.props.icon}
                </Box>
                <Box>
                  {child.props.title}
                </Box>
              </HStack>
            </Tab>
          )}
        </TabList>

        <TabPanels
        >
          {
            React.Children.map(props.children,
              (child: ReactElement<AnalyticaTableTabProps>) =>
                <TabPanel
                  // The next lineneeds to be here for some reason or it goes all fucky?
                  p={0}
                >
                  {child}
                </TabPanel>)}
        </TabPanels>
      </Tabs>

    </Box>
  )
}
