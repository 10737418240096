import { Tag } from "@chakra-ui/react";
import React, { ReactElement, useContext } from "react";
import { fetchTestsByClassId, fetchTestsByClassIdWithSearch, generateTest, } from "../../../../data/request";
import { BORDER_DEFAULT, ICON_STAFF } from "../../../../theme";
import { AddButton } from "../../../button";
import { AnalyticaTable } from "../../../table";
import { ClassTableTabProps } from "../class-profile";
import { Test } from "../../../../data/classes";
import { AccountContext } from "../../../Account";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { RTableActions } from "../../../table/action";
import { TestModal } from "../../../modal/test";
import { testTitle } from "../../../../data/functions";
import { RTableColumnProps } from "../../../table/column/r-table-column";

export const ClassTests: React.FC<ClassTableTabProps> = ({ ...props }) => {

  const { token } = useContext(AccountContext)

  const fetchTestsWithSearch = (token: string, search: string, setItems: (t: Test[]) => void, start: number, limit: number) => {
    fetchTestsByClassIdWithSearch(token, props.clazz.id, search, setItems, start, limit);
  }

  const fetchTests = (token: string, setItems: (t: Test[]) => void, start: number, limit: number) => {
    fetchTestsByClassId(token, props.clazz.id, setItems, start, limit);
  }

  const generateTestForClass = (classId: string) => {
    if (!token) {
      return
    }
    try {
      // TODO: need to ensure we get a rerender after this is executed
      generateTest(token, classId, props.callback ? props.callback : () => { })
    } catch (error) {
    }
  }

  // const download = (test: Test) => {
  //   if (!token) {
  //     return
  //   }
  //   let d = dayjs(new Date(test.created))
  //   downloadTestPDF(token, test.id, `${props.clazz.name}_${d.format("DDMMYY")}.pdf`)
  // }

  const testTitleComponent = (column: ReactElement<RTableColumnProps>, t: Test) => {
    return testTitle(t)
  }

  const testStatus = (column: ReactElement<RTableColumnProps>, t: Test) =>
    <Tag
      border={BORDER_DEFAULT}
      bg={t.completed ? "#F0FFF4" : "#FFF5EE"}
    >{t.completed ? "COMPLETED" : "PENDING"}</Tag>

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchTests}
        fetchItemsWithSearch={fetchTestsWithSearch}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/tests"}
      >
        <RTableActions name='RTableActions'>
          <AddButton
            title="Generate Test"
            testId="generate-test-button"
            onClick={() => generateTestForClass(props.clazz.id)}
          />
        </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Created" component={testTitleComponent} />
          <RTableColumn name="Status" component={testStatus} />
          <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
          <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
        </RTableColumns>
      </AnalyticaTable>

      <TestModal
        mode="add"
        org={props.org}
        clazz={props.clazz}
      />
    </>
  )
}
