import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Avatar, Box, Menu, MenuButton, MenuList, MenuItem, Center, HStack, Divider, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../Account';
import React from 'react';

export const Header = () => {

  const navigate = useNavigate()
  const { user, logout, pageTitle } = useContext(AccountContext)

  const userTitle = () => {
    if (!user) {
      return "?"
    }
    if (user && !user.given_name && !user.family_name) {
      return user.email
    }
    return `${user.given_name} ${user.family_name}`
  }

  let avatarMenu =
    <>
      <Menu placement="right-start">
        {({ isOpen }) => (
          <>
            <MenuButton test-id="avatar">
              <HStack>
                <Avatar name={userTitle()} src={""} />
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem test-id="profile" onClick={() => navigate("/profile")} >Profile</MenuItem>
              <MenuItem test-id="settings" onClick={() => navigate("/settings")} >Settings</MenuItem>
              <MenuItem test-id="signout" onClick={() => logout()}>Sign out</MenuItem>
            </MenuList>
          </>
        )}
      </Menu>
    </>

  return (
    <HStack>
      <Box w={10} />
      <Text fontSize={20}>{pageTitle}</Text>
      <Box w={10} />
      <Divider borderWidth="1px" borderColor="#D5D5D5" />
      <Box w={10} />
      <Center h='80px'>
        {avatarMenu}
      </Center>
      <Box w={10} />
    </HStack>
  )
}

