import { deleteData, downloadFile, fetchData, fetchItem, params, patchData, postData, putData } from './api';
import { Admin, Class, Org, Result, StaffMember, Student, Test } from './classes';


// Get Item

export async function fetchStudentById(token: string, studentId: string, setState: (s: Student) => void) {
  fetchItem(`v1/student/${studentId}`, token, setState)
}

export async function fetchTestById(token: string, testId: string, setState: (t: Test) => void) {
  fetchItem(`v1/test/${testId}`, token, setState)
}

export async function fetchResultById(token: string, resultId: string, setState: (r: Result) => void) {
  fetchItem(`v1/result/${resultId}`, token, setState)
}

export async function fetchAdminById(token: string, adminId: string, setState: (a: Admin) => void) {
  fetchItem(`v1/admin/${adminId}`, token, setState)
}

export async function fetchAdminByCognitoId(token: string, cognitoId: string, setState: (a: Admin) => void) {
  fetchItem(`v1/admin/cognito/${cognitoId}`, token, setState)
}

export async function fetchStaffMemberByCognitoId(token: string, cognitoId: string, setState: (s: StaffMember) => void) {
  fetchItem(`v1/staff/cognito/${cognitoId}`, token, setState)
}

export async function fetchStaffMemberById(token: string, staffId: string, setState: (s: StaffMember) => void) {
  fetchItem(`v1/staff/${staffId}`, token, setState)
}

export async function fetchOrgById(token: string, orgId: string, setState: (o: Org) => void) {
  fetchItem(`v1/org/${orgId}`, token, setState)
}

export async function fetchClassById(token: string, classId: string, setState: (c: Class) => void) {
  fetchItem(`v1/class/${classId}`, token, setState)
}

// Get Data

export async function fetchOrgs(token: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/org/all${params(start, limit)}`, token, setState)
}

export async function fetchOrgsWithSearch(token: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/org/all${params(start, limit)}&search=${search}`, token, setState)
}

export async function fetchClassesByOrgId(token: string, orgId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/classes${params(start, limit)}`, token, setState)
}

export async function fetchClassesByOrgIdWithSearch(token: string, orgId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/classes${params(start, limit, search)}`, token, setState)
}

export async function fetchClassesByStaffMemberId(token: string, staffMemberId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/staff/${staffMemberId}/classes${params(start, limit)}`, token, setState)
}

export async function fetchClassesByStaffMemberIdWithSearch(token: string, staffMemberId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/staff/${staffMemberId}/classes${params(start, limit, search)}`, token, setState)
}

export async function fetchClassesByStudentId(token: string, studentId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/student/${studentId}/classes${params(start, limit)}`, token, setState)
}

export async function fetchClassesByStudentIdWithSearch(token: string, studentId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/student/${studentId}/classes${params(start, limit, search)}`, token, setState)
}

export async function fetchAdmins(token: string, setState: (a: any[]) => void) {
  fetchData(`v1/admin/all`, token, setState)
}

export async function fetchAdminsWithSearch(token: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/admin/all${params(start, limit, search)}`, token, setState)
}

export async function fetchStaffByOrgId(token: string, orgId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/staff${params(start, limit)}`, token, setState)
}

export async function fetchStaffByOrgIdWithSearch(token: string, orgId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/staff${params(start, limit, search)}`, token, setState)
}

export async function fetchStaffByClassId(token: string, classId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/class/${classId}/staff${params(start, limit)}`, token, setState)
}

export async function fetchStaffByClassIdWithSearch(token: string, classId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/class/${classId}/staff${params(start, limit, search)}`, token, setState)
}

export async function fetchStaffByTestId(token: string, testId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/test/${testId}/staff${params(start, limit)}`, token, setState)
}

export async function fetchStaffByTestIdWithSearch(token: string, testId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/test/${testId}/staff${params(start, limit, search)}`, token, setState)
}

export async function fetchStudentsByOrgId(token: string, orgId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/students${params(start, limit)}`, token, setState)
}

export async function fetchStudentsByOrgIdWithSearch(token: string, orgId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/students${params(start, limit, search)}`, token, setState)
}

export async function fetchStudentsByClassId(token: string, classId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/class/${classId}/students${params(start, limit)}`, token, setState)
}

export async function fetchStudentsByClassIdWithSearch(token: string, classId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  console.log(start, limit, search)
  fetchData(`v1/class/${classId}/students${params(start, limit, search)}`, token, setState)
}

export async function fetchStudentsByStaffMemberId(token: string, staffMemberId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/staff/${staffMemberId}/students${params(start, limit)}`, token, setState)
}

export async function fetchStudentsByStaffMemberIdWithSearch(token: string, staffMemberId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/staff/${staffMemberId}/students${params(start, limit, search)}`, token, setState)
}

export const fetchAllStudentsByClassId = async (token: string, classId: string): Promise<Student[]> => {
  try {
    return fetchData(`v1/class/${classId}/students${params(0, -1)}`, token)
  } catch (error) {
    console.error('Failed to fetch students:', error);
    return [];
  }
}

export async function fetchAllResultsByTestId(token: string, testId: string): Promise<Result[]> {
  try {
    return fetchData(`v1/test/${testId}/results${params(0, -1)}`, token)
  } catch (error) {
    console.error('Failed to fetch results:', error);
    return [];
  }
}

export async function fetchTests(token: string, setState: (a: any[]) => void) {
  fetchData(`v1/tests`, token, setState)
}

export async function fetchTestsByClassId(token: string, classId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/class/${classId}/tests${params(start, limit)}`, token, setState)
}

export async function fetchTestsByClassIdWithSearch(token: string, classId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/class/${classId}/tests${params(start, limit, search)}`, token, setState)
}

export async function fetchTestsByStudentId(token: string, studentId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/student/${studentId}/tests${params(start, limit)}`, token, setState)
}

export async function fetchTestsByStudentIdWithSearch(token: string, studentId: string, search: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/student/${studentId}/tests${params(start, limit, search)}`, token, setState)
}

export async function fetchTestsByStaffMemberId(token: string, staffMemberId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/staff/${staffMemberId}/tests${params(start, limit)}`, token, setState)
}

export async function fetchResults(token: string, setState: (a: any[]) => void) {
  fetchData(`v1/results`, token, setState)
}

export async function fetchResultsByTestId(token: string, testId: string, setState: (a: any[]) => void, start: number, limit: number) {
  fetchData(`v1/tests/${testId}/results${params(start, limit)}`, token, setState)
}

export async function fetchResultsByStudentId(token: string, studentId: string, setState: (a: any[]) => void) {
  fetchData(`v1/student/${studentId}/results`, token, setState)
}

export async function fetchDashboardAggregations(token: string, setState: (a: any) => void) {
  fetchData(`v1/dashboard`, token, setState)
}

export async function fetchClassFeedback(token: string, classId: string, setState: (a: any) => void) {
  fetchData(`v1/class/${classId}/feedback`, token, setState)
}

// Post 

export async function addOrg(token: string, o: Org, callback: () => void) {
  postData(`v1/org`, o, token, callback)
}

export async function addClass(token: string, c: Class, callback: () => void) {
  postData(`v1/class`, c, token, callback)
}

export async function inviteAdmin(token: string, a: Admin, callback: () => void) {
  postData(`v1/admin/invite`, a, token, callback)
}

export async function inviteStaffMember(token: string, s: StaffMember, callback: () => void) {
  postData(`v1/staff/invite`, s, token, callback)
}

export async function addStudent(token: string, s: Student, callback: () => void) {
  try {
    await postData(`v1/student`, s, token, callback)
  } catch (error) {
    throw error
  }
}

export async function upsertResult(token: string, r: Result, testId: string, studentId: string) {
  postData(`v1/tests/${testId}/students/${studentId}/results`, r, token)
}

export async function generateTest(token: string, classId: string, callback: () => void) {
  postData(`v1/class/${classId}/test`, {}, token, callback)
}

// Put

export async function updateOrg(token: string, o: Org, callback: () => void) {
  putData(`v1/org/${o.id}`, o, token, callback)
}

export async function updateClass(token: string, c: Class, callback: () => void) {
  putData(`v1/class/${c.id}`, c, token, callback)
}

export async function updateAdmin(token: string, a: Admin, callback: () => void) {
  putData(`v1/admin/${a.id}`, a, token, callback)
}

export async function updateStaffMember(token: string, s: StaffMember, callback: () => void) {
  putData(`v1/staff/${s.id}`, s, token, callback)
}

export async function updateStudent(token: string, s: Student, callback: () => void) {
  putData(`v1/student/${s.id}`, s, token, callback)
}

export async function updateTest(token: string, t: Test, callback: () => void) {
  putData(`v1/test/${t.id}`, t, token, callback)
}

export async function completeTest(token: string, t: Test, callback: () => void) {
  putData(`v1/test/${t.id}/complete`, t, token, callback)
}

// Patch

export async function patchStudentClasses(token: string, studentId: string, added: string[]) {
  patchData(`v1/student/${studentId}/classes`, { classIds: added }, token)
}

// Delete

export async function deleteStudentClasses(token: string, studentId: string, removed: string[]) {
  deleteData(`v1/student/${studentId}/classes`, token, undefined, { classIds: removed })
}

export async function deleteAdmin(token: string, admin: Admin, callback: () => void) {
  deleteData(`v1/org/${admin.id}`, token, callback)
}

export async function deleteOrg(token: string, org: Org, callback: () => void) {
  deleteData(`v1/org/${org.id}`, token, callback)
}

export async function deleteClass(token: string, clazz: Class, callback: () => void) {
  deleteData(`v1/class/${clazz.id}`, token, callback)
}

export async function deleteStaffMember(token: string, staffMember: StaffMember, callback: () => void) {
  deleteData(`v1/staff/${staffMember.id}`, token, callback)
}

export async function deleteStudent(token: string, student: Student, callback: () => void) {
  deleteData(`v1/student/${student.id}`, token, callback)
}

export async function deleteTest(token: string, test: Test, callback: () => void) {
  deleteData(`v1/test/${test.id}`, token, callback)
}

// Download

export async function downloadTestPDF(token: string, testId: string, filename: string) {
  downloadPDF(`v1/test/${testId}/pdf`, filename, token)
}

export async function downloadImportTemplateCSV(token: string, filename: string) {
  downloadCSV('v1/template/import', filename, token)
}

async function downloadPDF(path: string, filename: string, token: string) {
  downloadFile(path, filename, 'application/pdf', token)
}

async function downloadCSV(path: string, filename: string, token: string) {
  downloadFile(path, filename, 'text/csv', token)
}

