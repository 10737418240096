import React, { useContext } from "react";
import { Class } from "../../../../data/classes";
import { fetchClassesByStaffMemberId, fetchClassesByStaffMemberIdWithSearch } from "../../../../data/request";
import { ICON_CLASS } from "../../../../theme";
import { AddButton } from "../../../button";
import { ModalContext } from "../../../Modal";
import { ClassModal } from "../../../modal/class";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { StaffMemberTableTabProps } from "../staff-member-profile";

export const StaffMemberClasses: React.FC<StaffMemberTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)

  const fetchClassesWithSearch = (token: string, search: string, setItems: (c: Class[]) => void, start: number, limit: number) =>
    fetchClassesByStaffMemberIdWithSearch(token, props.staffMember.id, search, setItems, start, limit);

  const fetchClasses = (token: string, setItems: (c: Class[]) => void, start: number, limit: number) =>
    fetchClassesByStaffMemberId(token, props.staffMember.id, setItems, start, limit);

  return (
    <>
      <AnalyticaTable
        icon={ICON_CLASS}
        fetchItems={fetchClasses}
        fetchItemsWithSearch={fetchClassesWithSearch}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/classes"}
      >
        <RTableActions name='RTableActions'>
          <AddButton
            title="New Class"
            testId="new-class-button"
            onClick={
              () => setModalContent(
                <ClassModal
                  mode="add"
                  org={props.org}
                />
              )
            }
          />
        </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn id="name" name="Name" />
          <RTableColumn id="yearLevel" name="Year Level" />
          <RTableColumn id="numStudents" name="#Students" />
          <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
          <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
        </RTableColumns>

      </AnalyticaTable>

    </>
  )
}
