import { useParams } from 'react-router-dom';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { fetchOrgById, fetchStaffMemberByCognitoId, fetchStaffByOrgId, fetchStaffByOrgIdWithSearch } from '../../../data/request';
import React from 'react'
import { AccountContext } from '../../Account';
import { Org, StaffMember } from '../../../data/classes';
import { Box } from '@chakra-ui/react';
import { AnalyticaTable } from '../../table';
import { Loading } from '../../loading';
import { ICON_STAFF } from '../../../theme';
import { AddButton } from '../../button';
import { fullname } from '../../../data/functions';
import { RTableColumn, RTableColumns } from '../../table/column';
import { RTableActions } from '../../table/action';
import { StaffMemberModal } from '../../modal/staff-member';
import { PageController } from '../../controller/page-controller';
import { ModalContext } from '../../Modal';
import { RTableColumnProps } from '../../table/column/r-table-column';

// Wierd naming bug when this is named Staff ¯\_(ツ)_/¯
export const StaffMembers = () => {

  let { orgId } = useParams();

  const [staffMember, setStaffMember] = useState<StaffMember | null>(null)
  const [org, setOrg] = useState<Org | null>();

  const { token, user, isStaff, setPageTitle } = useContext(AccountContext)

  const { setModalContent } = useContext(ModalContext)

  useEffect(() => {
    setPageTitle("Staff")
    if (token) {
      if (!staffMember && user) {
        fetchStaffMemberByCognitoId(token, user.sub, setStaffMember)
      }
      if (staffMember) {
        fetchOrgById(token, staffMember.orgId, setOrg)
      }
    }
  }, [setPageTitle, orgId, token, staffMember, user]);

  const userIsStaff = isStaff()
  if (!userIsStaff || !org || !staffMember) {
    return <Loading />
  }

  const fetchStaffMembersWithSearch = (token: string, search: string, setItems: (s: StaffMember[]) => void, start: number, limit: number) => {
    fetchStaffByOrgIdWithSearch(token, staffMember.orgId, search, setItems, start, limit);
  }

  const fetchStaffMembers = (token: string, setItems: (s: StaffMember[]) => void, start: number, limit: number) => {
    fetchStaffByOrgId(token, staffMember.orgId, setItems, start, limit);
  }

  const fullnameComponent = (column: ReactElement<RTableColumnProps>, s: StaffMember) => {
    return fullname(s)
  }

  return (
    <Box
      paddingX={5}
    >
      <PageController >
        <AnalyticaTable
          icon={ICON_STAFF}
          fetchItems={fetchStaffMembers}
          fetchItemsWithSearch={fetchStaffMembersWithSearch}
          searchable
          searchOnChange
          fullscreen
        >
          <RTableActions name='RTableActions'>
            <AddButton
              title='New Staff Member'
              testId='new-staff-member-button'
              onClick={() => setModalContent(
                <StaffMemberModal
                  mode="add"
                  org={org}
                  staffMember={staffMember}
                />
              )}
            />
          </RTableActions>

          <RTableColumns name='RTableColumns'>
            <RTableColumn name="Name" component={fullnameComponent} />
            <RTableColumn id="email" name="Email Address" />
            <RTableColumn id="numClasses" name="#Classes" />
            <RTableColumn id="numStudents" name="#Students" />
          </RTableColumns>

        </AnalyticaTable>

      </PageController>
    </Box>
  )
}
