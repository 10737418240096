import { Search2Icon } from "@chakra-ui/icons";
import { Button, Input, InputGroup, InputLeftElement, InputRightAddon } from "@chakra-ui/react";
import { useState } from "react";
import React from "react";
import { BORDER_DEFAULT, BORDER_PRIMARY_DARK, BORDER_PRIMARY_LIGHT, COLOUR_PRIMARY_DARK, COLOUR_PRIMARY_LIGHT, RADIUS_PRIMARY } from "../../theme";

interface Props {
  setSearch: Function
  searchOnChange: boolean
}

export const SearchBar: React.FC<Props> = ({
  setSearch,
  searchOnChange = false
}) => {

  const [searchText, setSearchText] = useState("")

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (searchOnChange) {
      setSearch(e.currentTarget.value)
    } else {
      setSearchText(e.currentTarget.value)
    }
  }

  const onClick = () => {
    setSearch(searchText)
  }

  return (
    <InputGroup >
      <InputLeftElement
        pointerEvents="none"
        children={
          <Search2Icon color={COLOUR_PRIMARY_DARK} />
        }
      />
      <Input
        type="text"
        placeholder="Search..."
        test-id="search-text"
        background={COLOUR_PRIMARY_LIGHT}
        _placeholder={{ color: COLOUR_PRIMARY_DARK }}
        borderRadius={RADIUS_PRIMARY}
        border={BORDER_DEFAULT}
        focusBorderColor={COLOUR_PRIMARY_DARK}
        onChange={onChange}
      />
      {!searchOnChange &&
        <InputRightAddon >
          <Button
            borderRightRadius={3.3}
            onClick={onClick}
          >
            Search
          </Button>
        </InputRightAddon>
      }

    </InputGroup>
  );
}
