import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Class, Student } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { fetchClassById, fetchStudentsByClassId, fetchStudentsByClassIdWithSearch } from "../../../../data/request";
import { ICON_IMPORT, ICON_STAFF } from "../../../../theme";
import { AccountContext } from "../../../Account";
import { AddButton } from "../../../button";
import { Loading } from "../../../loading";
import { ModalContext } from "../../../Modal";
import { StudentModal } from "../../../modal/student";
import { ImportModal } from "../../../modal/student/import-modal";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { ClassTableTabProps } from "../class-profile";

export const ClassStudents: React.FC<ClassTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)

  const { token } = useContext(AccountContext)
  const [clazz, setClass] = useState<Class | null>()
  let { classId } = useParams();

  const load = useCallback(() => {
    if (token && classId) {
      fetchClassById(token, classId, setClass)
    }
  }, [token, classId])

  useEffect(() => {
    load()
  }, [load])

  if (!clazz) {
    return <Loading />
  }

  const fetchStudentsWithSearch = (token: string, search: string, setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByClassIdWithSearch(token, props.clazz.id, search, setItems, start, limit);
  }

  const fetchStudents = (token: string, setItems: (s: Student[]) => void, start: number, limit: number) => {
    fetchStudentsByClassId(token, props.clazz.id, setItems, start, limit);
  }

  const fullnameComponent = (column: ReactElement<RTableColumnProps>, s: Student) => {
    return fullname(s)
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStudents}
        fetchItemsWithSearch={fetchStudentsWithSearch}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/students"}
      >
        <RTableActions name='RTableActions'>
          <AddButton
            title="New Student"
            testId="new-student-button"
            onClick={() => setModalContent(
              <StudentModal
                mode="add"
                org={props.org}
                clazz={props.clazz}
              />
            )}
          />
          <AddButton
            title="Import Students"
            testId="import-students-button"
            leftIcon={ICON_IMPORT}
            onClick={() => setModalContent(
              <ImportModal
                org={props.org}
                clazz={props.clazz}
                callback={() => load()}
              />
            )}
          />
        </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Name" component={fullnameComponent} />
          <RTableColumn id="level" name="Level" />
          <RTableColumn id="yearLevel" name="yearLevel" />
          <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
          <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
        </RTableColumns>
      </AnalyticaTable>
    </>
  )
}
