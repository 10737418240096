import axios, { AxiosRequestConfig } from 'axios';

declare const window: any;

const SERVER_API_URI = process.env.NODE_ENV === 'production' ? window.env.SERVER_API_URI
  : process.env.REACT_APP_SERVER_API_URI || "http://127.0.0.1:8080/api"

export const headers = (token: string) => {
  return { 'Authorization': `Bearer ${token}`, }
}

export const config = (token: string, data?: any) => {
  if (data) {
    return { headers: headers(token), data: data }
  }
  return { headers: headers(token) }
}

export const toServerPath = (path: string) => {
  return `${SERVER_API_URI}/${path}`
}

export const params = (start: number, limit: number, search?: string) => {
  let ret = ""
  if (start || limit || search) {
    ret = "?"
  }
  if (start !== undefined) {
    ret += `start=${start}`
  }
  if (limit !== undefined) {
    if (start !== undefined) {
      ret += "&"
    }
    ret += `limit=${limit}`
  }
  if (search !== undefined) {
    if (start !== undefined || limit !== undefined) {
      ret += "&"
    }
    ret += `search=${search}`
  }
  return ret
}

export const fetchItem = async (
  path: string,
  token: string,
  callback?: (a: any) => void
): Promise<any> => {
  try {
    const response = await axios.get(toServerPath(path), config(token));
    if (callback) {
      callback(response.data); // Call the callback with the fetched data
    }
    return response.data; // Return the fetched data
  } catch (error) {
    console.error('Error fetching item:', error);
    throw error; // Optionally rethrow the error to handle it upstream
  }
}

export const fetchData = async (
  path: string,
  token: string,
  callback?: (a: any[]) => void
): Promise<any> => {
  try {
    const response = await axios.get(toServerPath(path), config(token));
    if (callback) {
      callback(response.data); // Call the callback with the fetched data
    }
    return response.data; // Return the fetched data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Optionally rethrow the error to handle it upstream
  }
}

export const postData = async (
  path: string,
  data: any,
  token: string,
  callback?: () => void
): Promise<void> => {
  try {
    await axios.post(toServerPath(path), data, config(token));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}

export const putData = async (
  path: string,
  data: any,
  token: string,
  callback?: () => void
): Promise<void> => {
  try {
    await axios.put(toServerPath(path), data, config(token));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error('Error putting data:', error);
    throw error;
  }
}

export const patchData = async (
  path: string,
  data: any,
  token: string,
  callback?: () => void
): Promise<void> => {
  try {
    // Make the PATCH request
    await axios.patch(toServerPath(path), data, config(token));
    // Invoke the callback if provided
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error('Error patching data:', error);
    throw error; // Rethrow the error so it can be handled by the caller
  }
};

export const deleteData = async (
  path: string,
  token: string,
  callback?: () => void,
  data?: any
): Promise<void> => {
  try {
    await axios.delete(toServerPath(path), config(token, data));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
}

export const downloadFile = async (path: string, filename: string, filetype: string, token: string) => {
  const axiosConfig: AxiosRequestConfig = {
    responseType: "blob",
    headers: headers(token)
  }
  axios.get(toServerPath(path), axiosConfig)
    .then(
      (response) => {
        const fileURL = window.URL.createObjectURL(new File([new Blob([response.data])], filename, { type: filetype }))
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename
        alink.click();
      },
      (error) => {
        console.error(error)
      }
    )
}
