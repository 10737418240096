import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Org, Student } from "../../../data/classes";
import { fullname } from "../../../data/functions";
import { fetchOrgById, fetchStudentById } from "../../../data/request";
import { ICON_CLASS, ICON_TEST } from "../../../theme";
import { AccountContext } from "../../Account";
import { AddButton } from "../../button";
import { PageController } from "../../controller/page-controller";
import { Loading } from "../../loading";
import { ModalContext } from "../../Modal";
import { StudentModal } from "../../modal/student";
import { StatTile } from "../../stats";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { RAProfile } from "../profile";
import { StudentClasses } from "./classes";
import { StudentTests } from "./tests";

export interface StudentTableTabProps extends AnalyticaTableTabProps {
  org: Org
  student: Student
}

export const StudentProfile = () => {

  const [student, setStudent] = useState<Student | null>()
  const [org, setOrg] = useState<Org | null>()

  let { orgId, studentId } = useParams();

  const { token, setPageTitle } = useContext(AccountContext)

  const { setModalContent } = useContext(ModalContext)

  const load = useCallback(() => {
    setPageTitle("Students")
    if (token && orgId && studentId) {
      fetchOrgById(token, orgId, setOrg)
      fetchStudentById(token, studentId, setStudent)
    }
  }, [setPageTitle, token, orgId, studentId])

  useEffect(() => {
    load()
  }, [load]);

  if (!org || !student) {
    return <Loading />
  }

  const editClasses =
    <AddButton
      title="Edit Classes"
      leftIcon={ICON_CLASS}
      onClick={() => setModalContent(
        <StudentModal mode='classes' org={org} student={student} />
      )}
      testId="edit-student-classes-button"
    />

  return (
    <PageController>
      <RAProfile
        primary={fullname(student) || ''}
        secondary={org.name}
        edit={
          <StudentModal
            mode="update"
            student={student}
            org={org}
          />
        }
        delete={
          <StudentModal
            mode="delete"
            student={student}
            org={org}
          />
        }
        additionalActions={[editClasses]}
      >
        <StatTile title="Total Active Classes" value={student.numClasses} />
        <StatTile title="Tests Completed" value={student.numTestsCompleted} />
        <StatTile title="Average Score Percentage" units="%" value={student.avgScore} format="percentage" />
        <StatTile title="Average Time Remaining" units="s" value={student.avgTimeRemaining} />
      </RAProfile>

      <AnalyticaTableContainer>
        <StudentClasses title="Classes" icon={ICON_CLASS} org={org} student={student} testId="view-classes" />
        <StudentTests title="Tests" icon={ICON_TEST} org={org} student={student} testId="view-tests" />
      </AnalyticaTableContainer>

    </PageController>
  )
}
