import React, { ReactElement, useContext } from "react";
import { StaffMember } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { fetchStaffByClassId, fetchStaffByClassIdWithSearch } from "../../../../data/request";
import { ICON_STAFF } from "../../../../theme";
import { AddButton } from "../../../button";
import { ModalContext } from "../../../Modal";
import { StaffMemberModal } from "../../../modal/staff-member";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { RTableColumnProps } from "../../../table/column/r-table-column";
import { ClassTableTabProps } from "../class-profile";

export const ClassStaff: React.FC<ClassTableTabProps> = ({ ...props }) => {

  const { setModalContent } = useContext(ModalContext)

  const fetchStaffWithSearch = (token: string, search: string, setItems: (s: StaffMember[]) => void, start: number, limit: number) => {
    fetchStaffByClassIdWithSearch(token, props.clazz.id, search, setItems, start, limit);
  }

  const fetchStaff = (token: string, setItems: (s: StaffMember[]) => void, start: number, limit: number) => {
    fetchStaffByClassId(token, props.clazz.id, setItems, start, limit);
  }

  const fullnameComponent = (column: ReactElement<RTableColumnProps>, s: StaffMember) => {
    return fullname(s)
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStaff}
        fetchItemsWithSearch={fetchStaffWithSearch}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/staff"}
      >
        <RTableActions name='RTableActions'> </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Name" component={fullnameComponent} />
          <RTableColumn id="email" name="Email Address" />
          <RTableColumn id="numClasses" name="#Classes" />
          <RTableColumn id="numStudents" name="#Students" />
        </RTableColumns>

      </AnalyticaTable>

    </>
  )
}
