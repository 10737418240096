import { Box, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Spacer, Text } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import React, { ReactNode, useContext } from "react";
import { BORDER_DEFAULT, RADIUS_PRIMARY } from "../../theme";
import { AccountContext } from "../Account";
import { ModalContext } from "../Modal";
import { CancelButton, SaveButton } from "../button";
import { DeleteButton } from "../button/DeleteButton";

type FormMode = 'add' | 'update' | 'delete'

interface Props {
  name: string
  title: string
  description: string
  initialValues: object
  children: ReactNode | ReactNode[]
  onSubmit: (...args: any[]) => void
  mode?: FormMode
}

export const AnalyticaForm: React.FC<Props> = ({ ...props }) => {

  const { token } = useContext(AccountContext)
  const { onClose } = useContext(ModalContext)

  return (
    <Formik
      initialValues={props.initialValues || {}}
      validateOnMount
      onSubmit={(values, actions) => {
        setTimeout(() => {
          props.onSubmit(token, values, () => {
            actions.setSubmitting(false)
            actions.resetForm()
            onClose()
          })
        }, 1000)
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <ModalContent
            borderRadius={RADIUS_PRIMARY}
            border={BORDER_DEFAULT}>

            <ModalHeader>{props.title}</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text>{props.description}</Text>
              <Box pt={10} />
              {props.children}
              <Box pt={10} />
            </ModalBody>

            <ModalFooter>
              <CancelButton w="25%" />
              <Spacer />
              {props.mode && props.mode === 'delete' ?
                <DeleteButton
                  w="25%"
                  testId="delete-button"
                  isLoading={isSubmitting}
                  isDisabled={!isValid} />
                : <SaveButton
                  w="25%"
                  testId="save-button"
                  isLoading={isSubmitting}
                  isDisabled={!isValid} />
              }
            </ModalFooter>

          </ModalContent>
        </Form>
      )}
    </Formik>
  )
}

