import React from "react"
import { Account } from "./components/Account"
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from "./theme"
import { Outlet } from "react-router-dom"
import { ModalProvider } from "./components/Modal"

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Account>
        <ModalProvider>
          <Outlet />
        </ModalProvider>
      </Account>
    </ChakraProvider >
  )
}
