import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { completeTest, fetchClassById, fetchOrgById, fetchTestById } from "../../../data/request";
import { AccountContext } from "../../Account";
import React from "react";
import { Class, Org, Test } from "../../../data/classes";
import { Loading } from "../../loading";
import { AnalyticaTableContainer } from "../../table/analytica-table-container";
import { AnalyticaTableTabProps } from "../../table/analytica-table";
import { TestResults } from "./results";
import { TestStaff } from "./staff";
import { TestModal } from "../../modal/test";
import { RAProfile } from "../profile";
import { testTitle } from "../../../data/functions";
import { ICON_FLAG, ICON_RESULT, ICON_STAFF } from "../../../theme";
import { PageController } from "../../controller/page-controller";
import { AddButton } from "../../button";

export interface TestTableTabProps extends AnalyticaTableTabProps {
  org: Org
  test: Test
}

export const TestProfile = () => {

  let { orgId, testId } = useParams();

  const [org, setOrg] = useState<Org>()
  const [clazz, setClass] = useState<Class>()
  const [test, setTest] = useState<Test>()

  const { token, setPageTitle } = useContext(AccountContext)

  const loadClass = useCallback((classId: string) => {
    setPageTitle("Tests")
    if (token && classId) {
      fetchClassById(token, classId, setClass)
    }
  }, [setPageTitle, token])

  const load = useCallback(() => {
    if (token && orgId && testId) {
      fetchOrgById(token, orgId, setOrg)
      fetchTestById(token, testId, (t: Test) => {
        setTest(t)
        loadClass(t.classId)
      })
    }
  }, [token, orgId, testId, loadClass])

  useEffect(() => {
    load()
  }, [load]);

  if (!token || !org || !test || !clazz) {
    return <Loading />
  }

  const endTest = () => {
    completeTest(token, test, () => load())
  }

  return (
    <PageController>
      <RAProfile
        primary={testTitle(test) || ''}
        secondary={org.name}
        additionalActions={test.completed ? [] : [
          <AddButton
            title="End Test"
            testId="end-test"
            leftIcon={ICON_FLAG}
            onClick={() => endTest()}
          />
        ]}
        edit={
          <TestModal mode="update" org={org} clazz={clazz} test={test} />
        }
        delete={
          <TestModal mode="delete" org={org} clazz={clazz} test={test} />
        }
      />

      <AnalyticaTableContainer>
        <TestResults title="Results" icon={ICON_RESULT} org={org} test={test} testId="view-results" />
        <TestStaff title="Staff" icon={ICON_STAFF} org={org} test={test} testId="view-staff" />
      </AnalyticaTableContainer>

    </PageController>
  )
}
