import { AddIcon, DownloadIcon } from '@chakra-ui/icons'
import { theme as chakraTheme, defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react'
import { BiImport, BiSolidFileImport } from 'react-icons/bi'
import { CgOrganisation } from 'react-icons/cg'
import { FaBookOpen, FaFlagCheckered } from 'react-icons/fa'
import { FiEdit } from "react-icons/fi";
import { PiChalkboardTeacherBold, PiIdentificationCardFill, PiStudent, PiTestTube } from 'react-icons/pi'
import { RiAdminLine, RiDashboard2Line } from 'react-icons/ri'
import { RiDeleteBin6Line } from "react-icons/ri";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";

export const theme = extendTheme({
  colors: {
    brand: {
      "1": "#E0E0E0",
      "2": "#F2EFEA",
      "3": "white",
      "4": "#403D58",
      "5": "#70AE6E",
      "6": "#F4743B",
    }
  },
  fonts: {
    ...chakraTheme.fonts,
    // body: `"Roboto Condensed", Roboto, Arial`,
    // heading: `"Roboto Condensed", Roboto, Arial`,
    body: `"Plus Jakarta Sans", Roboto, Arial`,
    heading: `"Plus Jakarta Sans", Roboto, Arial`,
  }
})

const brandPrimary = defineStyle({
  textColor: '#F4743B',
  background: 'transparent',
})

export const buttonTheme = defineStyleConfig({
  variants: { brandPrimary },
})

export const COLOUR_PRIMARY_WHITE = 'white'
export const COLOUR_PRIMARY_GREY = '#D5D5D5'
export const COLOUR_PRIMARY_LIGHT = '#FFF5EE'
export const COLOUR_PRIMARY_DARK = '#403D58'
export const COLOUR_PRIMARY_DARK_OPACITY_6 = '#403d58ba'

export const COLOUR_SECONDARY_LIGHT = '#F0FFF4'
export const COLOUR_SECONDARY_DARK = '#666276'

export const COLOUR_TRANSPARENT = 'transparent'

// export const BORDER_DEFAULT = `2px solid ${COLOUR_PRIMARY_DARK_OPACITY_6}`
export const BORDER_DEFAULT = `2px solid ${COLOUR_PRIMARY_GREY}`
export const BORDER_WHITE = '2px solid white'
export const BORDER_PRIMARY_LIGHT = `2px solid ${COLOUR_PRIMARY_LIGHT}`
export const BORDER_PRIMARY_DARK = `2px solid ${COLOUR_PRIMARY_DARK}`

export const RADIUS_PRIMARY = 7
export const MARGIN_PRIMARY = 5
export const FONT_PRIMARY = 25

const DEFAULT_ICON_SIZE = 25
const SECONDARY_ICON_SIZE = 22

export const ICON_ADMIN = <RiAdminLine size={DEFAULT_ICON_SIZE} />
export const ICON_ORG = <CgOrganisation size={DEFAULT_ICON_SIZE} />
export const ICON_STAFF = <PiIdentificationCardFill size={DEFAULT_ICON_SIZE} />
export const ICON_CLASS = <PiChalkboardTeacherBold size={DEFAULT_ICON_SIZE} />
export const ICON_STUDENT = <PiStudent size={DEFAULT_ICON_SIZE} />
export const ICON_TEST = <PiTestTube size={DEFAULT_ICON_SIZE} />
export const ICON_RESULT = <FaBookOpen size={DEFAULT_ICON_SIZE} />

export const ICON_DASHBOARD = <RiDashboard2Line size={DEFAULT_ICON_SIZE} />
export const ICON_DOWNLOAD = <DownloadIcon size={DEFAULT_ICON_SIZE} />
export const ICON_IMPORT = <BiSolidFileImport size={SECONDARY_ICON_SIZE} />
// export const ICON_IMPORT = <BiImport size={DEFAULT_ICON_SIZE} />
export const ICON_ADD = <AddIcon size={DEFAULT_ICON_SIZE} />
export const ICON_EDIT = <FiEdit size={DEFAULT_ICON_SIZE} />
export const ICON_DELETE = <RiDeleteBin6Line size={DEFAULT_ICON_SIZE} />
export const ICON_FLAG = <FaFlagCheckered size={DEFAULT_ICON_SIZE} />
export const ICON_TICK = <TiTick size={DEFAULT_ICON_SIZE} />
export const ICON_CROSS = <ImCross size={DEFAULT_ICON_SIZE} />
