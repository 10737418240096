import { IconButton } from "@chakra-ui/react";
import React, { MouseEventHandler } from "react";
import { COLOUR_PRIMARY_DARK, COLOUR_TRANSPARENT, ICON_EDIT } from "../../../theme";
import { AnalyticaButtonProps } from "./../AnalyticaButton";

interface Props extends AnalyticaButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const EditIconButton = (props: Props) => {
  return (
    <IconButton
      icon={ICON_EDIT}
      aria-label=""
      onClick={props.onClick}
      bg={COLOUR_TRANSPARENT}
      color={COLOUR_PRIMARY_DARK}
      test-id={props.testId}
    />
  )
}
