import React from "react";
import { Class } from "../../../../data/classes";
import { fetchClassesByStudentId, fetchClassesByStudentIdWithSearch } from "../../../../data/request";
import { ICON_CLASS } from "../../../../theme";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { StudentTableTabProps } from "../student-profile";

export const StudentClasses: React.FC<StudentTableTabProps> = ({ ...props }) => {

  const fetchClassesWithSearch = (token: string, search: string, setItems: (c: Class[]) => void, start: number, limit: number) =>
    fetchClassesByStudentIdWithSearch(token, props.student.id, search, setItems, start, limit);

  const fetchClasses = (token: string, setItems: (c: Class[]) => void, start: number, limit: number) =>
    fetchClassesByStudentId(token, props.student.id, setItems, start, limit);

  return (
    <>
      <AnalyticaTable
        icon={ICON_CLASS}
        fetchItems={fetchClasses}
        fetchItemsWithSearch={fetchClassesWithSearch}
        render={props.render}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/classes"}
      >
        <RTableActions name='RTableActions'> </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn id="name" name="Name" />
          <RTableColumn id="yearLevel" name="Year Level" />
          <RTableColumn id="numStudents" name="#Students" />
          <RTableColumn id="avgScore" name="Avg. Score" format="percentage" />
          <RTableColumn id="avgTimeRemaining" name="Avg. Time Remaining (s)" />
        </RTableColumns>

      </AnalyticaTable>
    </>
  )
}
