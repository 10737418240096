import { Person, Test } from "./classes";

export const fullname = (p: Person): string | undefined => {
  if (!p.preferredName && !p.lastName) {
    return undefined
  }
  return `${p.preferredName || ''} ${p.lastName || ''}`
}

export const testTitle = (t: Test) => {
  let date = new Date(t.created)
  const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });
  return date.toDateString() + " - " + formatter.format(date);
}
