import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import { StaffMember } from "../../../../data/classes";
import { fullname } from '../../../../data/functions';
import { fetchStaffByTestId, fetchStaffByTestIdWithSearch } from "../../../../data/request";
import { ICON_STAFF } from "../../../../theme";
import { AddButton } from "../../../button";
import { AnalyticaTable } from "../../../table";
import { RTableActions } from "../../../table/action";
import { RTableColumn, RTableColumns } from "../../../table/column";
import { TestTableTabProps } from "../test-profile";

export const TestStaff: React.FC<TestTableTabProps> = ({ ...props }) => {

  const { onOpen } = useDisclosure()

  const fetchStaffWithSearch = (token: string, search: string, setItems: (s: StaffMember[]) => void, start: number, limit: number) => {
    fetchStaffByTestIdWithSearch(token, props.test.id, search, setItems, start, limit);
  }

  const fetchStaff = (token: string, setItems: (s: StaffMember[]) => void, start: number, limit: number) => {
    fetchStaffByTestId(token, props.test.id, setItems, start, limit);
  }

  return (
    <>
      <AnalyticaTable
        icon={ICON_STAFF}
        fetchItems={fetchStaff}
        fetchItemsWithSearch={fetchStaffWithSearch}
        searchable
        searchOnChange
        to={"/orgs/" + props.org.id + "/staff"}
      >
        <RTableActions name='RTableActions'> </RTableActions>

        <RTableColumns name='RTableColumns'>
          <RTableColumn name="Name" component={fullname} />
          <RTableColumn id="email" name="Email Address" />
          <RTableColumn id="numClasses" name="#Classes" />
          <RTableColumn id="numStudents" name="#Students" />
        </RTableColumns>

      </AnalyticaTable>
    </>
  )
}
